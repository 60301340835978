




import { Component, Mixins } from 'vue-property-decorator'

import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component
export default class Bank extends Mixins(PermissionsMixin) {
  private mounted () {
    if (this.$route.name === 'manager.bank') {
      if (this.hasPermission(this.Permission.DEPOSITORY_MATERIALS_VIEW)) {
        this.$router.replace({ name: 'manager.bank.depository.courses' })
      } else if (this.hasPermission(this.Permission.DEPOSITORY_MANUAL_VIEW)) {
        this.$router.replace({ name: 'manager.bank.depository.manual' })
      } else if (this.hasSomePermissions([this.Permission.BANK_BASE_VIEW, this.Permission.BANK_PERSONAL_VIEW])) {
        this.$router.replace({ name: 'manager.bank.exercises' })
      } else {
        this.$router.replace({ name: 'manager' })
      }
    }
  }
}
